/*!
 * Start Bootstrap - Agency Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

 @font-face{ 
	font-family: 'Blogger Sans';
    font-weight: 300;
	src: url('./../fonts/blogger-sans.woff') format('woff'),
	     url('./../fonts/blogger-sans.woff2') format('woff2')
}

@font-face{ 
	font-family: 'Blogger Sans';
    font-weight: 700;
	src: url('./../fonts/blogger-sans-bold.woff') format('woff'),
	     url('./../fonts/blogger-sans-bold.woff2') format('woff2')	     
}

@font-face{ 
	font-family: 'Flaticon';
    font-weight: 400;
	src: url('./../fonts/flaticon.woff') format('woff'),
	     url('./../fonts/flaticon.woff2') format('woff2')	     
}


@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family:"Flaticon";
        src: url(./../fonts/flaticon.svg#Flaticon) format("svg")
    }
}

body {
    overflow-x: hidden;
    font-family: "Blogger Sans",Arial,sans-serif;
    color: $secondary-dark;
}

.text-muted {
    color: #777;
}


#footer h3 {
    font-size: 18px;
    color: $rose;
    line-height: 1.5em;
    overflow: visible;
    text-decoration: none;
    text-decoration: none;
    letter-spacing: 2px;    
}

#footer h4 {
    color: $secondary;
    display: flex;
    flex-flow: row wrap;
}

#footer h3:after {
    content: '';
    position: absolute;
    display: block;
    height: 5px;
    background: url(../img/underline_light.svg) 0% 0%!important;
    background-repeat: no-repeat;
    background-size: cover;
    width: 80%
}

#footer p {
    font-size: 16px;
}

.notices {
    color: #eee;
    padding: 10px 10px 3px;
    margin-bottom: 20px;
    border-radius: 3px;

    &.error {
        background-color: #c00;
    }
    &.success, &.warning {
        background-color: #8B8B8B;
    }
}

.text-primary {
    color: $primary;
}

p {
    font-size: 14px;
    line-height: 1.75;
}

p.large {
    font-size: 16px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
    outline: 0;
}

a {
    color: $primary;
    text-decoration: none!important;
}

a:hover,
a:focus,
a:active,
a.active {
    color: $secondary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    font-family: 'Blogger Sans',Arial,sans-serif;
    font-weight: 700;
}

.img-centered {
    margin: 0 auto;
}

.bg-light-gray {
    background-color: #f7f7f7;
}

.bg-darkest-gray {
    background-color: #222;
}

.bg-geometry {
    background: url(../img/geometry.svg) 0px 0px repeat;   
}

.btn-primary {
    border-color: $primary;
    text-transform: uppercase;
    font-family: Blogger Sans,Arial,sans-serif;
    font-weight: 700;
    color: #fff;
    background-color: $primary;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    border-color: $primary;
    color: #fff;
    background-color: $secondary;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    border-color: $primary;
    background-color: $primary;
}

.btn-primary .badge {
    color: $primary;
    background-color: #fff;
}

.btn {       
    font-family: Blogger Sans,Arial,sans-serif;
    font-size: 16px;        
    background-color: $secondary;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 2px;
    color: #fff;    
    border: none;
    border-radius: 40px;
    padding: 15px 30px;
    transition: all .3s ease;
    text-transform: uppercase
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.open .dropdown-toggle.btn-xl {
    // border-color: $primary;
    background-color: $primary;
    color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(164,213,128,.3),0 4px 23px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(164,213,128,.3)
}

.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    background-image: none;
}

.btn-xl.disabled,
.btn-xl[disabled],
fieldset[disabled] .btn-xl,
.btn-xl.disabled:hover,
.btn-xl[disabled]:hover,
fieldset[disabled] .btn-xl:hover,
.btn-xl.disabled:focus,
.btn-xl[disabled]:focus,
fieldset[disabled] .btn-xl:focus,
.btn-xl.disabled:active,
.btn-xl[disabled]:active,
fieldset[disabled] .btn-xl:active,
.btn-xl.disabled.active,
.btn-xl[disabled].active,
fieldset[disabled] .btn-xl.active {
    border-color: $primary;
    background-color: $primary;
}

.btn-xl .badge {
    color: $primary;
    background-color: #fff;
}

.navbar-nav > li {    
    margin: 10px 10px;    
}

.navbar-default {
    border-color: transparent;
    background-color: #fff;
}

.navbar-default .navbar-brand {
    font-family: "Blogger Sans",Arial,cursive;
    color: $primary;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:active,
.navbar-default .navbar-brand.active {
    color: $secondary;
}

.navbar-default .navbar-collapse {
    border-color: rgba(255,255,255,.02);
}

.navbar-default .navbar-toggle {
    border-color: $primary;
    background-color: $primary;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: $primary;
}

.navbar-default .nav li a {    
    font-family: 'Blogger Sans', Arial,sans-serif;    
    display: inline-block;
    color: #000;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 800;
    border: none;
    padding: 0 0 10px;
    line-height: 18px;
    transition: 0.3s all;
}

.navbar-default .nav li a:hover,
.navbar-default .nav li a:focus {
    outline: 0;
    color: $primary;
}

.navbar-default .nav li a::after, .navbar-default .navbar-nav>.active>a:after, .fc .fc-button-group > .fc-button:after {
    content: '';
    opacity: 0;
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 8px;
    background-image: url(./../img/underline.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 0%;
    transition: 0.3s all;
}

.navbar-default .nav li a:hover::after, .navbar-default .navbar-nav>.active>a:after, .fc .fc-button-group > .fc-button-active:after {
    color: $primary!important;
    opacity: 100%;
    width: 100%;
}

.navbar-default .navbar-nav>.active>a {
    color: $primary;
    border-radius: 0;
    background-color: transparent;
}

.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: $primary;
    background-color: transparent;
}

@media(min-width:768px) {
    .navbar-default {
        padding: 25px 0;
        border: 0;
        background-color: #fff;
        -webkit-transition: padding .3s;
        -moz-transition: padding .3s;
        transition: padding .3s;
    }

    .navbar-default .navbar-brand {
        font-size: 2em;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
    }

    .navbar-default .navbar-nav>.active>a {
        border-radius: 3px;
    }

    .navbar-default.navbar-shrink {
        padding: 10px 0;
        // background-color: #222;
    }

    .navbar-default.navbar-shrink .navbar-brand {
        font-size: 1.5em;
    }
    
}

header {
    text-align: center;
    color: #fff;        
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
}

.navbar-header .contact {
    font-size: 18px;
    letter-spacing: 0.5px;
    font-weight: 700;
    color: #808080;
    text-transform: uppercase;
    background: url(../img/logo.svg) 10px 0px / contain no-repeat;    
    padding-left: 80px;
    display: flex;
    flex-flow: column wrap;
}

.navbar-header .contact div {
    display: flex;
    flex-flow: row wrap;
}


@media(max-width:1000px) {
    .phone > a {        
        font-size: 18px;        
    }
}

header .intro-text {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    padding: 50px 0px 150px;
}

header h1 {
    margin-bottom: 25px;
    font-family: Arial,sans-serif;
    font-size: 22px;
    font-style: italic;
    line-height: 22px;
    text-transform: none;
}

header h2 { 
    margin-bottom: 25px;
    text-transform: uppercase;
    font-family: Blogger Sans,Arial,sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    text-shadow: 0px 1px 2px #3b3c3b;
}

@media(min-width:768px) {
    header .intro-text {
        padding-top: 150px;
        padding-bottom: 200px;
    }

    header .intro-text h1 {
        margin-bottom: 25px;
        font-family: Arial,sans-serif;
        font-size: 40px;
        font-style: italic;
        line-height: 40px;
    }

    header .intro-text h2 {
        margin-bottom: 40px;
        text-transform: uppercase;
        font-family: 'Blogger Sans',Arial,sans-serif;        
        font-weight: 700;
    }
}

section {
    // padding: 100px 0;
}

section h2{
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 40px;
    margin-bottom: 40px;
    color: $rose;        
    font-weight: 700;
    line-height: 50px;    
    letter-spacing: 2px;
}

section p {
    font-size: 24px;
    color: #808080;
    line-height: 1.5em;
}

@media(min-width:768px) {
    section {
        padding: 0px 0;
    }
}

#lessons {    
        display: block;
        overflow: hidden;        
        background: url(../img/geometry.svg) 0px 0px repeat;   
}

#lessons:after {
    content: '';
    display: block;
    width: 100%;
    height: 200px;
    margin-top: -1px;
    position: absolute;
    z-index: 1;
    background: url(../img/geometry.svg) 0% 100%!important;
    clip-path: polygon(0% 0%,100% 0%,0% 100%)
}

#lessons svg {    
    margin-top: -128px!important;
    height: 130px;
    width: 100%;
}

#lessons .service-image {
    width: 100%;
}

#lessons .service-image img {
    object-fit: contain;
}

#school {    
    display: block;
    overflow: hidden;        
    background: url(../img/geometry.svg) 0px 0px repeat;
    padding-bottom: 60px;
}

#school svg {    
margin-top: -128px!important;
height: 130px;
width: 100%;
}

.service-heading {
    margin: 15px 0;
    text-transform: none;
}
.service-grid {
    margin-top: 30px;
    display: grid;    
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 15px;
}
.service-title {
    width: 300px;
    margin: 10px auto 0px;
    font-family: "Blogger Sans";
    text-transform: uppercase
}

.service-content {
    font-size: 18px;
    color: #808080!important;
    margin: .2em 0;
    line-height: 1.3
}

.service-content ul {
    width: 300px;
    margin: 0px auto;
    list-style: none;    
    padding: unset;
}

.service-content ul li::marker {
    content: "✔ ";
    color: #4da465;
}

.service-icon {
    float: left;
    margin: 0px auto;
    // width: 80px;
    display: block;
    height: 80px;
    margin: 0px auto;
}

@media(max-width:767px) {    
    .service-icon {
        float: none;
        width: 80px
    }

    header h2, section h2 {
        font-size: 26px;
        line-height: 1.5em;
    }

    .navbar-header .contact {
        background: none;
        padding-left: 20px;
    }
    
}

.service-icon i {
    font-size: 80px
}

.service-icon i:after {
    position: absolute;
    color: #fff;
    font-size: 70%;
    line-height: 1.5em;
    margin: 0px 12px;
}

.service-icon i:before {
    opacity: .6;
    position: absolute;    
    margin-left: auto;
    margin-right: auto
}

.rotate10:before {
    transform: rotate(10deg)
}

.rotate-10:before {
    transform: rotate(-10deg)
}



#mission:after {
    content: '';
    display: block;
    width: 100%;
    height: 200px;
    margin-top: -40px;
    position: absolute;    
    background: url(../img/geometry.svg) 0% 100%!important;
    clip-path: polygon(100% 0%,100% 100%,0% 100%)
}

#mission h2 {
    color: #fff;
    text-shadow: 0px 1px 2px #3b3c3b;
}

#mission {
    display: block;
    overflow: hidden;
    padding: 160px 0 160px 0!important;
    position: relative;
    color: #fff;    
}

.mission-content {
    width: 100%;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 120px 0;    
}

.mission-content h3 {
    color: #fff;
    margin: 0;
    text-shadow: 0px 1px 2px #3b3c3b
}

#timetable {
    padding-bottom: 200px;
}


#portfolio .portfolio-item {
    right: 0;
    margin: 0 0 15px;
}

#portfolio .portfolio-item .portfolio-link {
    display: block;
    position: relative;
    margin: 0 auto;
    max-width: 400px;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all ease .5s;
    -moz-transition: all ease .5s;
    transition: all ease .5s;
    background: rgba(254,209,54,.9); /* Fallback when no plugin support */
    background: rgba($primary, .9);
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover:hover {
    opacity: 1;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 20px;
    margin-top: -12px;
    text-align: center;
    font-size: 20px;
    color: #fff;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content i {
    margin-top: -12px;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h3,
#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h4 {
    margin: 0;
}

#portfolio .portfolio-item .portfolio-caption {
    margin: 0 auto;
    padding: 25px;
    max-width: 400px;
    text-align: center;
    background-color: #fff;
}

#portfolio .portfolio-item .portfolio-caption h4 {
    margin: 0;
    text-transform: none;
}

#portfolio .portfolio-item .portfolio-caption p {
    margin: 0;
    font-family: Arial,sans-serif;
    font-size: 16px;
    font-style: italic;
}

#portfolio * {
    z-index: 2;
}

@media(min-width:767px) {
    #portfolio .portfolio-item {
        margin: 0 0 30px;
    }
}

.fc .fc-daygrid-day-number {        
    color: $rose;    
    font-weight: 700;
    text-decoration: none;
}

.fc .fc-col-header-cell-cushion {
    text-decoration: none;
}

.fc .fc-button-primary {background: $primary;}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none!important;
}

.fc .fc-button-group > .fc-button {
    flex: 1 1 auto;
    position: relative;
    font-family: "Blogger Sans", Arial, sans-serif;
    color: #000;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 800;
    border: none;    
    line-height: 18px;
    transition: 0.3s all;
}

.fc .fc-button-group > .fc-button-active, .fc .fc-button-group > .fc-button:hover {
    color: $primary!important;
}

.fc .fc-button-primary:focus {
    box-shadow: none!important;
}

.fc-h-event .fc-event-time {
    overflow: unset!important;
    margin-right: 1px;
    margin-left: 1px;
}

.fc .fc-toolbar.fc-header-toolbar {
    flex-flow: row wrap;
}

:root {
    --fc-small-font-size: 1em!important;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
    --fc-neutral-text-color: #808080;
    --fc-border-color: #ddd;
  
    --fc-button-text-color: #000!important;
    --fc-button-bg-color: transparent!important;
    --fc-button-border-color: transparent!important;
    --fc-button-hover-bg-color: transparent!important;
    --fc-button-hover-border-color: transparent!important;
    --fc-button-active-bg-color: transparent!important;
    --fc-button-active-border-color: transparent!important;
  }



.timeline {
    position: relative;
    padding: 0;
    list-style: none;
}

.timeline:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    background-color: #f1f1f1;
}

.timeline>li {
    position: relative;
    margin-bottom: 50px;
    min-height: 50px;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li .timeline-panel {
    float: right;
    position: relative;
    width: 100%;
    padding: 0 20px 0 100px;
    text-align: left;
}

.timeline>li .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline>li .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline>li .timeline-image {
    z-index: 100;
    position: absolute;
    left: 0;
    width: 80px;
    height: 80px;
    margin-left: 0;
    border: 7px solid #f1f1f1;
    border-radius: 100%;
    text-align: center;
    color: #fff;
    background-color: $primary;
}

.timeline>li .timeline-image h4 {
    margin-top: 12px;
    font-size: 10px;
    line-height: 14px;
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    padding: 0 20px 0 100px;
    text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline>li:last-child {
    margin-bottom: 0;
}

.timeline .timeline-heading h4 {
    margin-top: 0;
    color: inherit;
}

.timeline .timeline-heading h4.subheading {
    text-transform: none;
}

.timeline .timeline-body>p,
.timeline .timeline-body>ul {
    margin-bottom: 0;
}

@media(min-width:768px) {
    .timeline:before {
        left: 50%;
    }

    .timeline>li {
        margin-bottom: 100px;
        min-height: 100px;
    }

    .timeline>li .timeline-panel {
        float: left;
        width: 41%;
        padding: 0 20px 20px 30px;
        text-align: right;
    }

    .timeline>li .timeline-image {
        left: 50%;
        width: 100px;
        height: 100px;
        margin-left: -50px;
    }

    .timeline>li .timeline-image h4 {
        margin-top: 16px;
        font-size: 13px;
        line-height: 18px;
    }

    .timeline>li.timeline-inverted>.timeline-panel {
        float: right;
        padding: 0 30px 20px 20px;
        text-align: left;
    }
}

@media(min-width:992px) {
    .timeline>li {
        min-height: 150px;
    }

    .timeline>li .timeline-panel {
        padding: 0 20px 20px;
    }

    .timeline>li .timeline-image {
        width: 150px;
        height: 150px;
        margin-left: -75px;
    }

    .timeline>li .timeline-image h4 {
        margin-top: 30px;
        font-size: 18px;
        line-height: 26px;
    }

    .timeline>li.timeline-inverted>.timeline-panel {
        padding: 0 20px 20px;
    }
}

@media(min-width:1200px) {
    .timeline>li {
        min-height: 170px;
    }

    .timeline>li .timeline-panel {
        padding: 0 20px 20px 100px;
    }

    .timeline>li .timeline-image {
        width: 170px;
        height: 170px;
        margin-left: -85px;
    }

    .timeline>li .timeline-image h4 {
        margin-top: 40px;
    }

    .timeline>li.timeline-inverted>.timeline-panel {
        padding: 0 100px 20px 20px;
    }
}

.team-member {
    margin-bottom: 50px;
    text-align: center;
}

.team-member img {
    margin: 0 auto;
    border: 7px solid #fff;
}

.team-member h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
}

.team-member p {
    margin-top: 0;
}

aside.clients img {
    margin: 50px auto;
}

#footer {
    background-color: #222;
    background-image: url(../img/email-pattern.png);
    background-position: center;
    background-repeat: repeat;
}

#footer:before {
    content: '';
    display: block;
    width: 100%;
    height: 200px;
    margin-top: -199px;
    position: absolute;
    background: url(//owl.xmut.ru/wp-content/themes/oneline-lite/./images/email-pattern.png) 0% 100%!important;
    clip-path: polygon(100% 0%,100% 100%,0% 100%)
}

#footer h2 {
    color: $rose;
}

#footer .form-group {
    margin-bottom: 25px;
}

#footer .form-group input,
#footer .form-group textarea {        
    appearance: none;    
    background: 0 0;
    color: #dbbd80;
    border: 1px solid #f1c2a5;    
    padding: 12px;
    margin: 0 8px 4px 0;
    border-radius: 8px;
    background: rgba(255,255,255,.5);
    clear: left;    
    transition: .3s;
    width: 100%
}

#footer .form-group input.form-control {
    height: auto;
}

#footer.form-group textarea.form-control {
    height: 236px;
}

#footer.form-control:focus {
    border-color: $primary;
    box-shadow: none;
}

#footer::-webkit-input-placeholder {
    text-transform: uppercase;
    font-family: Blogger Sans,Arial,sans-serif;
    font-weight: 700;
    color: #bbb;
}

#footer:-moz-placeholder {
    text-transform: uppercase;
    font-family: Blogger Sans,Arial,sans-serif;
    font-weight: 700;
    color: #bbb;
}

#footer::-moz-placeholder {
    text-transform: uppercase;
    font-family: Blogger Sans,Arial,sans-serif;
    font-weight: 700;
    color: #bbb;
}

#footer:-ms-input-placeholder {
    text-transform: uppercase;
    font-family: Blogger Sans,Arial,sans-serif;
    font-weight: 700;
    color: #bbb;
}

#footer .text-danger {
    color: #e74c3c;
}

footer {
    padding: 25px 0;
    text-align: center;
}

footer span.copyright {
    text-transform: uppercase;
    text-transform: none;
    font-family: Blogger Sans,Arial,sans-serif;
    line-height: 40px;
}

footer ul.quicklinks {
    margin-bottom: 0;
    text-transform: uppercase;
    text-transform: none;
    font-family: Blogger Sans,Arial,sans-serif;
    line-height: 40px;
}

.notices.success {
    background: $primary;    
}

.notices.success p {
    color: #fff;
}

ul.social li a {    
    border-radius: 50%;
    color: #fff;
    font-size: 40px;
    transition: all 0.3s;    
    margin: 0px 20px 0px 0px;
    padding: 10px;
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
    outline: 0;
}

.portfolio-modal .modal-content {
    padding: 100px 0;
    min-height: 100%;
    border: 0;
    border-radius: 0;
    text-align: center;
    background-clip: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.portfolio-modal .modal-body {
    overflow-y: scroll;
}

.portfolio-modal .modal-content h2 {
    margin-bottom: 15px;
    font-size: 3em;
}

.portfolio-modal .modal-content p {
    margin-bottom: 30px;
}

.portfolio-modal .modal-content p.item-intro {
    margin: 20px 0 30px;
    font-family: Arial,sans-serif;
    font-size: 16px;
    font-style: italic;
}

.portfolio-modal .modal-content ul.list-inline {
    margin-top: 0;
    margin-bottom: 30px;
}

.portfolio-modal .modal-content img {
    margin-bottom: 30px;
}

.portfolio-modal .close-modal {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 75px;
    height: 75px;
    background-color: transparent;
    cursor: pointer;
}

.portfolio-modal .close-modal:hover {
    opacity: .3;
}

.portfolio-modal .close-modal .lr {
    z-index: 1051;
    width: 1px;
    height: 75px;
    margin-left: 35px;
    background-color: #222;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.portfolio-modal .close-modal .lr .rl {
    z-index: 1052;
    width: 1px;
    height: 75px;
    background-color: #222;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.portfolio-modal.modal {
  .modal-content {
    height: 100%;
    overflow-y: scroll;
  }
}


::selection {
    color: #fff;
    text-shadow: none;
    background: $rose;
}

img::selection {
    background: 0 0;
}

img::-moz-selection {
    background: 0 0;
}

body {
    webkit-tap-highlight-color: $rose;
}

// icons


[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
 font-family: 'flaticon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.flaticon-octogon:before {
    content: "\e900";
  }
  .flaticon-pentagon:before {
    content: "\e901";
  }
  .flaticon-square:before {
    content: "\e902";
  }
  .flaticon-triangle:before {
    content: "\e903";
  }
  .flaticon-plush-toy:after {
    content: "\f101";
  }
  .flaticon-theater:after {
    content: "\f10e";
  }
  .flaticon-crayons:after {
    content: "\f106";
  }
  .flaticon-heart:after {
    content: "\f10b";
  }
  .flaticon-child:after {
    content: "\f100";
  }
  .flaticon-owl:after {
    content: "\f108";
  }
  .flaticon-teddy-bear:after {
    content: "\f103";
  }
  .flaticon-rocking-horse:after {
    content: "\f10d";
  }
  .flaticon-puzzle:after {
    content: "\f107";
  }
  .flaticon-big-ben:after {
    content: "\f109";
  }
  .flaticon-train:after {
    content: "\f104";
  }
  
  .flaticon-fb:before {
    content: "\e904";
  }
  .flaticon-vk:before {
    content: "\e90a";
  }
  .flaticon-ig:before {
    content: "\e906";
  }
  .flaticon-arrow-right:before {
    content: "\e907";
  }
  
  .flaticon-viber:before {
      content: "\e906";
  }
  
  .flaticon-whatsapp:before {
  content: "\e908";
  }
  
  .flaticon-telegram:before {
      content: "\e909";
  }

  .flaticon-envelope:before {
    content: "\e90d";
  }
  .flaticon-map-marker:before {
    content: "\e905";
  }
  .flaticon-phone:before {
    content: "\e90b";
  }

.map {
    border-radius: 8px;
    width: 100%; height: 400px;
    box-shadow: 0 10px 25px -5px rgba(137, 193, 104, 0.6);
}

img.leaflet-tile  {    
    filter: saturate(0.2)!important;
}

// animate floating button

@-webkit-keyframes come-in {
    0% {
      -webkit-transform: translatey(100px);
              transform: translatey(100px);
      opacity: 0;
    }
    30% {
      -webkit-transform: translateX(-50px) scale(0.4);
              transform: translateX(-50px) scale(0.4);
    }
    70% {
      -webkit-transform: translateX(0px) scale(1.2);
              transform: translateX(0px) scale(1.2);
    }
    100% {
      -webkit-transform: translatey(0px) scale(1);
              transform: translatey(0px) scale(1);
      opacity: 1;
    }
  }
  @keyframes come-in {
    0% {
      -webkit-transform: translatey(100px);
              transform: translatey(100px);
      opacity: 0;
    }
    30% {
      -webkit-transform: translateX(-50px) scale(0.4);
              transform: translateX(-50px) scale(0.4);
    }
    70% {
      -webkit-transform: translateX(0px) scale(1.2);
              transform: translateX(0px) scale(1.2);
    }
    100% {
      -webkit-transform: translatey(0px) scale(1);
              transform: translatey(0px) scale(1);
      opacity: 1;
    }
  }
  * {
    margin: 0;
    padding: 0;
  }
  
    
  .floating-container {
    z-index: 100;
    position: fixed;
    width: 70px;
    height: 100px;
    bottom: 0;
    right: 0;
    margin: 10px;
  }
  .floating-container:hover {
    height: 350px;
  }
  .floating-container:hover .floating-button {    
    box-shadow: 0 10px 25px rgba(137 193 104 / 60%);  
    transform: translatey(5px);    
    transition: all 0.3s;
  }
  .floating-container:hover .element-container .float-element:nth-child(1) {    
            animation: come-in 0.4s forwards 0.2s;
  }
  .floating-container:hover .element-container .float-element:nth-child(2) {    
            animation: come-in 0.4s forwards 0.4s;
  }
  .floating-container:hover .element-container .float-element:nth-child(3) {    
            animation: come-in 0.4s forwards 0.6s;
  }
  .floating-container:hover .element-container .float-element:nth-child(4) {    
            animation: come-in 0.4s forwards 0.8s;
  }
  .floating-container .floating-button {
    position: absolute;
    width: 65px;
    height: 65px;
    bottom: 0;
    padding: 0px;
    left: 0;
    right: 0;
    margin: auto;
    color: white;
    line-height: 64px;
    text-align: center;
    font-size: 40px;
    z-index: 100;
    box-shadow: 0 10px 25px -5px rgba(137 193 104 / 60%);
    cursor: pointer;
    transition: all 0.3s;

  }
  .floating-container .float-element {
    position: relative;
    display: block;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 15px auto;
    color: white;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    line-height: 50px;
    z-index: 0;
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  .floating-container .float-element .material-icons {
    vertical-align: middle;
    font-size: 16px;
  }
  
  .floating-container .float-element.btn-vk {
    background: #4C75A3;
    box-shadow: 0 20px 20px -10px rgba(76, 117, 163, 0.5);
}

.floating-container .float-element.btn-whatsapp {
    background: #25D366;
    box-shadow: 0 20px 20px -10px rgba(37, 211, 102, 0.5);
}

.floating-container .float-element.btn-viber {
    background: #59267c;
    box-shadow: 0 20px 20px -10px rgba(89, 38, 124, 0.5);
}

.floating-container .float-element.btn-telegram {
    background: #229ED9;
    box-shadow: 0 20px 20px -10px rgba(34, 158, 217, 0.5);
}